import React from "react";
import "./AboutHeader.scss";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const AboutHeader = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativePath: { eq: "about-page_hero-1532.jpg" } }
        sort: { order: ASC, fields: relativePath }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                formats: WEBP
                webpOptions: { quality: 100 }
                quality: 100
              )
            }
          }
        }
      }
    }
  `);

  const aboutHeaderBackground = getImage(images.allFile.edges[0].node);

  return (
    <section className="hero">
      <figure>
        <GatsbyImage
          loading="lazy"
          className="hero--background"
          image={aboutHeaderBackground}
          alt="Blurry background"
        />
      </figure>
      <div className="hero-text">
          <div class="aboutTitle">
            <h1>
              <span class="bolognaWhite smallTextbolognaDefault">for the</span>
              <span class="bolognaDefault smallTextbolognaDefault">LOVE</span>
              <span class="bolognaWhite smallTextbolognaDefault">of</span>
              <span class="bolognaDefault smallTextbolognaDefault">BOLOGNA</span>
            </h1>
          </div>
      </div>
    </section>
  );
};

export default AboutHeader;
