import React from "react";
import "./AboutDescription.scss";
import { Row, Col, Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AboutDescription = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: {
            in: ["about-background.jpg", "about-s.jpg", "about-m.jpg"]
          }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                formats: WEBP
                webpOptions: { quality: 100 }
                quality: 100
              )
            }
          }
        }
      }
    }
  `);

  const background = getImage(images.allFile.edges[0].node);
  const small = getImage(images.allFile.edges[1].node);
  const large = getImage(images.allFile.edges[2].node);

  return (
    <Container className="about-content" as={"section"}>
      <GatsbyImage
        loading="lazy"
        className="about-content--background"
        image={background}
        alt="Background"
      />
      <Row className="about-row">
        <Col xs={12} xl={8} sm={12} lg={6} md={6} className="p-0">
          <div className="about-text">
            <p>
              Inspired chefs everywhere are moving bologna from the kid’s
              lunchbox to the family table and creating a whole new generation
              of bologna lovers.
            </p>
            <p>
              Popular restaurants across the country are serving up bologna
              culinary creations that people are standing in line to order.
            </p>
            <p>
              Whether it’s an award-winning Fried Bologna Sandwich in Chicago or
              a Cajun creation in New Orleans, it’s on trend, exciting and just
              down-right…good for the soul.
            </p>
            <p>
              Serve your own surprising twist on an old favorite with Bologna
              Eggs Benedict for breakfast, our signature Fried Bologna Sandwich
              for lunch, or really shake it up with Bologna Tacos for dinner.
            </p>
            <p>
              Check out these and all of our chef-inspired recipes for your next
              meal. Share the love…your family will approve!
            </p>
          </div>
        </Col>
        <Col className="d-none d-lg-block d-md-block d-xl-block largeImg">
          <GatsbyImage
            loading="lazy"
            className="la"
            image={large}
            alt="For the Love of Bologna"
          />
        </Col>
        <Col className="d-sm-block d-xs-block d-lg-none d-md-none d-xl-none about-pics smallImg">
          <GatsbyImage
            loading="lazy"
            image={small}
            alt="For the Love of Bologna"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutDescription;
