import React from "react";
import Layout from "../components/Layout";
import AboutHeader from "../components/AboutHeader/AboutHeader";
import AboutDescription from "../components/AboutDescription/AboutDescription";

const About = () => {
  return (
    <Layout title="About" description="">
      <article>
        <AboutHeader />
        <AboutDescription />
      </article>
    </Layout>
  );
};

export default About;
